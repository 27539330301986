import { render, staticRenderFns } from "./PortalAdsStats.vue?vue&type=template&id=63c921f0&scoped=true&"
import script from "./PortalAdsStats.vue?vue&type=script&lang=js&"
export * from "./PortalAdsStats.vue?vue&type=script&lang=js&"
import style0 from "./PortalAdsStats.vue?vue&type=style&index=0&id=63c921f0&lang=scss&scoped=true&"
import style1 from "./PortalAdsStats.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c921f0",
  null
  
)

export default component.exports